
import Vue from 'vue';
// @ts-ignore
import { AisHierarchicalMenu } from 'vue-instantsearch';

import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

export default Vue.extend({
  name: 'InstantSearchHierarchicalMenu',
  components: {
    AisHierarchicalMenu,
  },
  data() {
    return {
      attributes: Object.freeze([
        EFacetNameRefinementList.HierarchicalCategoriesLevel1,
        EFacetNameRefinementList.HierarchicalCategoriesLevel2,
        EFacetNameRefinementList.HierarchicalCategoriesLevel3,
      ]),
      limit: 100, // Basically no limit
      sortBy: ['name'],
    };
  },
  methods: {
    // TODO: Definitely get rid of Root Catalog indexed products.
    // https://linear.app/selency/issue/SEL-1614
    transformItems(items: unknown[]) {
      if (this.$accessor?.featureFlags?.list?.[EFeatureFlags.AlgoliaHideRootCatalogCategory]) {
        // @ts-expect-error
        return items.filter((item) => item?.label !== 'Root Catalog');
      }

      return items;
    },
  },
});
