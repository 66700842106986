
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisSortBy, AisStats } from 'vue-instantsearch';

import IcCross from '@/assets/svg/ic_cross.svg?inline';
import { EFacetNameNumericMenu, EFacetNameRange, EFacetNameRefinementList, EFacetNameToggleRefinement } from '@/domain/core/algolia/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { RadioOption } from '@/infrastructure/core/components/input/Radio.interface';
import { InputRangeGroupItem } from '@/infrastructure/core/components/instantsearch/InstantSearch.interface';
import { AisNumericMenuItem } from '@/infrastructure/externalServices/algolia/types';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';

export default Vue.extend({
  name: 'InstantSearchFilters',
  components: {
    AisSortBy,
    AisStats,
    IcCross,
  },
  props: {
    filters: {
      type: String,
      default: '',
    },
    algoliaSortIndices: {
      type: Array as PropType<RadioOption[]>,
      required: true,
    },
    // Custom "sold out" filter that relies on the "status" facet value.
    // The data bubbles all the way up to the [parent].vue that contains InstantSearchSSR.vue. Data flow : [parent].vue <-> InstantSearchSSR.vue <-> InstantSearchFilters.vue.
    // Prop drilling IS of course an issue but this use case does not justify using a dedicated store module. We wan't to keep Algolia's footprint as low as possible.
    isSoldOutFilterActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EFacetNameRefinementList: Object.freeze(EFacetNameRefinementList),
      EFacetNameRange: Object.freeze(EFacetNameRange),
      EFacetNameToggleRefinement: Object.freeze(EFacetNameToggleRefinement),
      EFacetNameNumericMenu: Object.freeze(EFacetNameNumericMenu),
      baseFacetsI18nPath: 'page.search.facets',
      disjunctiveFacetsRefinements: [],
    };
  },
  computed: {
    isSoldOutFilterActiveModel: {
      get(): boolean {
        return this.isSoldOutFilterActive;
      },
      set(value: boolean): void {
        this.$emit('update:is-sold-out-filter-active', value);
      },
    },
    isB2BPro(): boolean {
      return this.$accessor.user.isB2BPro;
    },
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
    isCategoryPage(): boolean {
      return this.$route.name === ERouteName.Category;
    },
    shouldEnableHierarchicalMenu(): boolean {
      return this.$accessor?.featureFlags?.list?.[EFeatureFlags.AlgoliaHierarchicalMenuForCategories];
    },
    shouldEnableMondalRelayFilter(): boolean {
      return this.$accessor?.featureFlags?.list?.[EFeatureFlags.OperationFreeMondialRelay];
    },
    shouldDisplayTradeProgramFilter(): boolean {
      const { email, isAdmin } = this.$accessor.user;
      // NOTE: this has to be handled by the API. This feature has been required by the product team (01/2023). 🤡
      const isSelencyEmail = email.includes('@selency');

      return isAdmin || isSelencyEmail;
    },
    shouldDisplayQualityMarkFilter(): boolean {
      return this.$accessor.user.isExpert;
    },
    dimensionsRefinement(): InputRangeGroupItem[] {
      const baseI18nPath = `${this.baseFacetsI18nPath}.dimensions`;
      const { Depth, Height, Width } = EFacetNameRange;

      return [Height, Width, Depth].map((facetName) => ({
        facetName,
        facetTitle: this.$t(`${baseI18nPath}.${facetName}`),
        suffix: 'cm',
      }));
    },
    publicationDateFilters(): AisNumericMenuItem[] {
      const oneYearAgoTimestamp = this.computePastDate(12);
      const sixMonthsAgoTimestamp = this.computePastDate(6);
      const threeMonthsAgoTimestamp = this.computePastDate(3);

      return [
        { label: this.$t(`${this.baseFacetsI18nPath}.publicationDate.oneYear`), start: oneYearAgoTimestamp },
        { label: this.$t(`${this.baseFacetsI18nPath}.publicationDate.sixMonths`), start: sixMonthsAgoTimestamp },
        { label: this.$t(`${this.baseFacetsI18nPath}.publicationDate.threeMonths`), start: threeMonthsAgoTimestamp },
      ];
    },
    shouldDisplaySalesFilters(): boolean {
      return this.$accessor?.featureFlags?.list?.[EFeatureFlags.Sales];
    },
    shouldDisplayDesignerFilter(): boolean {
      const isDesignerPage = this.$route.name === ERouteName.Designer;

      return !isDesignerPage;
    },
    superSalesFilters(): AisNumericMenuItem[] {
      const minimumSale = 40;

      return [
        {
          label: this.$t(`${this.baseFacetsI18nPath}.sales.superSale.title`),
          start: minimumSale,
        },
      ];
    },
    sellerNoteFilters(): AisNumericMenuItem[] {
      return [
        { label: this.$t(`${this.baseFacetsI18nPath}.sellerNote.moreOrEqualThanFourAndAHalf`), start: 4.5 },
      ];
    },
  },
  methods: {
    closeFiltersMobile({ scrollToTop = false }): void {
      this.$emit('toggle-filters', { scrollToTop });
    },
    emitCurrentRefinementsChange(event: Event): void {
      this.$emit('update:current-refinements', event);
    },
    computePastDate(monthsAgo: number): number {
      const date = new Date();

      date.setHours(0, 0, 0, 0); // Truncate timestamp at midnight to enable URL sharing on the same day.
      date.setMonth(date.getMonth() - monthsAgo);

      return Math.floor(date.getTime() / 1000);
    },
  },
});
