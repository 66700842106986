import { render, staticRenderFns } from "./InstantSearchCategoriesFilter.vue?vue&type=template&id=2c929048"
import script from "./InstantSearchCategoriesFilter.vue?vue&type=script&lang=ts"
export * from "./InstantSearchCategoriesFilter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputCheckbox: require('/home/runner/work/webapp/webapp/components/atoms/form/input/InputCheckbox.vue').default,InstantSearchCheckboxes: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchCheckboxes.vue').default,InstantSearchCollapsible: require('/home/runner/work/webapp/webapp/components/molecules/instantsearch/InstantSearchCollapsible.vue').default})
